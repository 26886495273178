import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import { getWidgetById } from "./openai-widget/lib/firestore";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

export const init = async (widgetId: string, selector: string, isCluster?: boolean) => {
  console.log("WIDGET_DEBUG init started: time", new Date().getTime());
  const element = document.querySelector(selector);
  if (element) {
    let shadowContainer = element.shadowRoot;
    if (!shadowContainer) {
      shadowContainer = element.attachShadow({ mode: "open" });
    } else {
      return;
    }
    const shadowRootElement = document.createElement("div");

    shadowContainer.appendChild(shadowRootElement);

    const root = ReactDOM.createRoot(shadowRootElement!);

    const cache = createCache({
      key: "css-shadow",
      container: shadowContainer,
    });

    root.render(
      <CacheProvider value={cache}>
        <App widgetId={widgetId} isCluster={isCluster} />
      </CacheProvider>
    );

    const widget = await getWidgetById(widgetId);

    if (!widget) {
      return {
        error: `Widget with id "${widgetId}" not found.`,
      };
    }

    return "Widget loaded successfully";
  } else {
    return {
      error: `Element with selector "${selector}" not found.`,
    };
  }
};

// to check widget in development mode

// const rootElement = document.getElementById("root");
// const shadowContainer = rootElement!.attachShadow({ mode: "open" });
// const shadowRootElement = document.createElement("div");
// shadowContainer.appendChild(shadowRootElement);

// const root = ReactDOM.createRoot(shadowRootElement!);

// const cache = createCache({
//   key: "css",
//   prepend: true,
//   container: shadowContainer,
// });

// root.render(
//   <CacheProvider value={cache}>
//     <App widgetId="NzRWcEq2aLBJxjantGRO" />
//   </CacheProvider>
// );
