// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/simplebar-react/dist/simplebar.min.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/react-scripts/node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/react-lazy-load-image-component/src/effects/blur.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* scrollbar */
/* lazy image */

/* lightbox
@import 'yet-another-react-lightbox/styles.css';
@import 'yet-another-react-lightbox/plugins/captions.css';
@import 'yet-another-react-lightbox/plugins/thumbnails.css'; */
  
.ul-selectable-options {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  
  .selectable-options-li {
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 6px;
  }
  .selectable-options-li:hover {
    text-decoration: underline;
  }`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA,cAAc;AAId,eAAe;;AAEf;;;8DAG8D;;AAE9D;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;EACV;;EAEA;IACE,eAAe;IACf,eAAe;IACf,kBAAkB;EACpB;EACA;IACE,0BAA0B;EAC5B","sourcesContent":["/* scrollbar */\n@import 'simplebar-react/dist/simplebar.min.css';\n\n@import 'react-lazy-load-image-component/src/effects/blur.css';\n/* lazy image */\n\n/* lightbox\n@import 'yet-another-react-lightbox/styles.css';\n@import 'yet-another-react-lightbox/plugins/captions.css';\n@import 'yet-another-react-lightbox/plugins/thumbnails.css'; */\n  \n.ul-selectable-options {\n    display: flex;\n    flex-direction: column;\n    gap: 6px;\n  }\n  \n  .selectable-options-li {\n    cursor: pointer;\n    font-size: 14px;\n    margin-bottom: 6px;\n  }\n  .selectable-options-li:hover {\n    text-decoration: underline;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
