import React from "react";
import { styled, keyframes } from "@mui/material/styles";
import { Stack, Typography } from '@mui/material';

interface TypingIndicatorProps {
  marginRight?: number | string;
}

const blink = keyframes`
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

const TypingAnimation = styled('span')({
  display: 'inline-flex',
  alignItems: 'flex-end',
  '& > span': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    marginRight: 2,
    animation: `${blink} 1s linear infinite`,
    '&:nth-of-type(1)': {
      animationDelay: '-0.32s',
    },
    '&:nth-of-type(2)': {
      animationDelay: '-0.16s',
    },
  },
});

const TypingIndicator = ({
  connectingNewAssistant,
}: {
  connectingNewAssistant?: boolean;
}) => (
  <Stack
    direction="row"
    alignItems="center"
    justifyContent="center"
    spacing={1}
  >
    <Typography variant="body2" color="textSecondary">
      {connectingNewAssistant ? 'Connecting to new assistant ' : ''}
    </Typography>
    <TypingAnimation>
      <span />
      <span />
      <span />
    </TypingAnimation>
  </Stack>
);

export default TypingIndicator;
