import React from "react";

// @mui
import {
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Box,
  alpha,
  useTheme,
  SvgIcon,
} from "@mui/material";
// utils
import { useContext } from "react";
// @types
// components
import BadgeStatus from "../components/badge-status";
import { CustomAvatar } from "../components/custom-avatar";
import { ChatContext } from "../context";
import { bgGradient } from "../theme/css";
import { useWidgetSettingsContext } from "../settings";
import Iconify from "../components/iconify";
import chroma from "chroma-js";
import { DEFAULT_LOGO } from "../lib/firestore";
import { ReturnType, useBoolean } from '../hooks/use-boolean';

// ----------------------------------------------------------------------

export default function ChatHeaderDetail() {
  const { setChatOpen, clearConversation, isOpenDirectSms, chatData } =
    useContext(ChatContext);
  const theme = useTheme();

  const settings = useWidgetSettingsContext();
  const { status } = useContext(ChatContext);

  const isAnimated =
    (!settings.assistantAvatar as any)?.preview ||
    (settings.assistantAvatar as any)?.preview === DEFAULT_LOGO;

  const isUserSentSmsToday = chatData?.directSmsSentAt
    ? new Date(chatData?.directSmsSentAt).toDateString() ===
      new Date().toDateString()
    : false;

  const SmsTooltip = ({ isOpenDirectSms }: { isOpenDirectSms: ReturnType }) => {
    return (
      <span translate="no">
        {isOpenDirectSms.value ? 'AI Chat' : 'Direct SMS to the owner'}
      </span>
    );
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        p: (theme) => theme.spacing(2),
        bgcolor: 'background.paper',
        ...bgGradient({
          direction: '0deg',
          startColor: alpha(theme.palette.primary.main, 0.8),
          endColor: alpha(
            theme.palette.mode === 'dark'
              ? theme.palette.primary.dark
              : theme.palette.primary.main,
            1,
          ),
        }),
        position: 'relative',
        // backgroundColor: "common.white",
      }}
    >
      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="center"
          width={1}
          sx={{
            minHeight: 32,
          }}
        >
          <CustomAvatar
            src={
              isAnimated
                ? 'https://firebasestorage.googleapis.com/v0/b/chat-bot-ai-147ba.appspot.com/o/default.png?alt=media&token=9a9263b4-5d30-415e-941a-8ebc2f1ff0aa'
                : (settings.assistantAvatar as any)?.preview || DEFAULT_LOGO
            }
            sx={{
              width: 100,
              height: 100,
              position: 'absolute',
              pointerEvents: 'none',
              top: 12,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 999,
              background: 'transparent',
            }}
            alt={settings.name}
            name={settings.name}
          />

          <Box
            sx={{
              height: 60,
              width: 1,
              opacity: 0.9,
              background: `linear-gradient(180deg, ${alpha(
                theme.palette.primary.main,
                0.5,
              )} 0%, ${
                settings.themeMode === 'dark'
                  ? theme.palette.background.paper
                  : 'rgba(255,255,255,1)'
              } 100%)`,
              backdropFilter: 'blur(30px)',
              position: 'absolute',
              bottom: -60,
              zIndex: 998,
            }}
          />

          <Box sx={{ position: 'absolute', top: 0, right: 5 }}>
            {/* <Tooltip title="Close the chat"> */}
            <IconButton
              onClick={() => setChatOpen(false)}
              sx={{
                p: 0.5,
              }}
            >
              <Iconify
                sx={{
                  color:
                    chroma(theme.palette.primary.main || 'white').luminance() >
                    0.4
                      ? '#000'
                      : 'white',
                }}
                width={26}
                icon="iconamoon:close-bold"
              />
            </IconButton>
            {/* </Tooltip> */}
          </Box>
          <Box sx={{ position: 'absolute', top: 0, left: 4, zIndex: 9999 }}>
            {settings.whatsappNumber && !settings?.redirectToWhatsapp && (
              <Tooltip title="Go to WhatsApp">
                <IconButton
                  onClick={() => {
                    const message = encodeURIComponent('/start');
                    window.open(
                      'https://wa.me/' +
                        settings.whatsappNumber?.slice(1) +
                        (settings?.prefillMessageToStartAssistant
                          ? '?text=' + message
                          : ''),
                      '_blank',
                    );
                  }}
                >
                  <Iconify width={48} height={48} icon="logos:whatsapp-icon" />
                </IconButton>
              </Tooltip>
            )}
            {settings?.directSmsPhoneNumber && !isUserSentSmsToday && (
              <Tooltip title={<SmsTooltip isOpenDirectSms={isOpenDirectSms} />}>
                <IconButton onClick={isOpenDirectSms.onToggle}>
                  <Iconify
                    width={48}
                    height={48}
                    icon={
                      isOpenDirectSms.value
                        ? 'lineicons:open-ai'
                        : 'fa6-solid:comment-sms'
                    }
                    sx={{
                      color: theme.palette.getContrastText(
                        theme.palette.primary.main,
                      ),
                    }}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box sx={{ position: 'absolute', bottom: -1, right: 5, zIndex: 999 }}>
            <Tooltip title="Clear the conversation">
              <IconButton
                onClick={clearConversation}
                sx={{
                  p: 1,
                }}
              >
                <Iconify
                  sx={{
                    color:
                      chroma(
                        theme.palette.primary.main || 'white',
                      ).luminance() > 0.4
                        ? '#000'
                        : 'white',
                  }}
                  width={20}
                  icon="carbon:clean"
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
