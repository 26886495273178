import React, { useEffect } from "react";

import { getFirestore } from "firebase/firestore";
import type { FC } from "react";
import { FirebaseAppProvider, FirestoreProvider } from "reactfire";
import {appCheckInit, widgetFirebaseApp} from "../lib/firebase";
import {
  ReCaptchaEnterpriseProvider,
  initializeAppCheck,
} from "firebase/app-check";
import {RE_CAPTCHA_ENTERPRISE_KEY} from "../config";
import {getAuth} from "firebase/auth";

// HOC for FirebaseAppProvider
const withFirebaseApp = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithFirebaseApp(props: P) {

    if (!appCheckInit) {
      return null;
    }
    return (
      <FirebaseAppProvider firebaseApp={widgetFirebaseApp}>
        <Component {...props} />
      </FirebaseAppProvider>
    );
  };
};

// HOC for Providers
const withProviders = <P extends object>(Component: FC<P>): FC<P> => {
  return function WithProviders(props: P) {
    const firestoreInstance = getFirestore(widgetFirebaseApp);

    return (
      <FirestoreProvider sdk={firestoreInstance}>
        <Component {...props} />
      </FirestoreProvider>
    );
  };
};

// Combining HOCs
export const withFirebaseProviders = <P extends object>(Component: FC<P>): FC<P> => {
  // First wrap the component with FirebaseAppProvider
  const WithFirebaseApp = withProviders(Component);
  // Then wrap WithFirebaseApp with AuthProvider and FirestoreProvider
  return withFirebaseApp(WithFirebaseApp);
};
